// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import 'assets/scss/bootstrap-utils';
@import 'assets/scss/variables';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700%7cPoppins:100,200,300,400,500,600,700,800,900&amp;display=swap');

// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700%7cPoppins:100,200,300,400,500,600,700,800,900&amp;display=swap');

$main-primary: mat.m2-define-palette($front-end-primary);
$main-accent: mat.m2-define-palette($front-end-accent, A200, A100, A400);
$main-warn: mat.m2-define-palette($front-end-warn);

$main-typography: mat.m2-define-typography-config(
  $font-family:
    '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif'
);

$main-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $main-primary,
      accent: $main-accent,
      warn: $main-warn
    ),
    typography: $main-typography
  )
);

@include mat.core-theme($main-theme);
@include mat.all-component-themes($main-theme);

* {
  transition: background 0.25s ease-in, color 0.25s ease-in;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  //overflow-y: hidden;
  overflow-y: visible;
}

body,
h1,
p {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

.alert-error {
  background-color: Red;
}

.alert-warn-foreground {
  color: #ec820c;
}

.alert-success {
  background-color: #dff2bf;
}

.alert-success-foreground {
  color: #7fc21b;
}

.alert-warn {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1,
h2,
h3 {
  color: mat.m2-get-color-from-palette($main-primary);
}

h1 {
  font-weight: 600;
}

h2 {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}
.mat-mdc-input-element:disabled {
  color: black;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: black;
}
.mat-mdc-paginator {
  background-color: whitesmoke !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: transparent !important;
}
.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
  color: black !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.text-danger {
  color: #dc3545 !important;
}

.mat-mdc-tab-header {
  border-bottom: lightgrey;
  border-bottom-style: solid;
  border-bottom-width: thin;
}
.form-btn-div {
  display: flex;
}
.form-button-end {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.form-button-start {
  display: flex;
  justify-content: start;
  width: 50%;
}

.master-btn-style {
  margin-right: 2%;
  min-width: fit-content;
}

.submit-btn-style {
  margin-right: 2%;
}

.mat-mdc-cell {
  padding: 0px !important;
}

.mat-mdc-card {
  padding: 16px !important;
}
table tr td:first-child,
table tr th:first-child {
  padding-left: 16px !important; // Adjust the padding value as needed
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0px !important;
}

.mat-mdc-row {
  height: 48px !important;
}
.clear-btn {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  padding-left: 2% !important;
  font-size: medium !important;
  align-items: center !important;
}

.clear-btn .mat-mdc-button-persistent-ripple::before {
  background-color: transparent !important;
}
.currency-form-style .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  margin-top: 6px !important;
}

.mdc-dialog--open .mat-mdc-dialog-surface {
  padding: 24px !important;
}

.filter-btn-style .mat-mdc-button-persistent-ripple::before {
  background-color: transparent !important;
}
