:root {
  --background: #edf2f9;
  --text: #617185;

  --primary-50 : #F3E5E4;
  --primary-100 : #E0BEBC;
  --primary-200 : #cb9390;
  --primary-300 : #b66763;
  --primary-400 : #a74741;
  --primary-500 : #972620;
  --primary-600 : #8f221c;
  --primary-700 : #841c18;
  --primary-800 : #7a1713;
  --primary-900 : #690d0b;
  --primary-a100 : #ff9d9b;
  --primary-a200 : #ff6a68;
  --primary-a400 : #ff3835;
  --primary-a700 : #ff1f1c;
  --primary-contrast-50 : #000000;
  --primary-contrast-100 : #000000;
  --primary-contrast-200 : #000000;
  --primary-contrast-300 : #000000;
  --primary-contrast-400 : #ffffff;
  --primary-contrast-500 : #ffffff;
  --primary-contrast-600 : #ffffff;
  --primary-contrast-700 : #ffffff;
  --primary-contrast-800 : #ffffff;
  --primary-contrast-900 : #ffffff;
  --primary-contrast-A100 : #000000;
  --primary-contrast-A200 : #000000;
  --primary-contrast-A400 : #000000;
  --primary-contrast-A700 : #000000;


  --accent-50: #f3e2e2;
  --accent-100: #e0b7b7;
  --accent-200: #cc8888;
  --accent-300: #b75858;
  --accent-400: #a73434;
  --accent-500: #981010;
  --accent-600: #900e0e;
  --accent-700: #850c0c;
  --accent-800: #7b0909;
  --accent-900: #6a0505;
  --accent-a100: #ff9a9a;
  --accent-a200: #ff6767;
  --accent-a400: #ff3434;
  --accent-a700: #ff1a1a;
  --accent-contrast-50: #000000;
  --accent-contrast-100: #000000;
  --accent-contrast-200: #000000;
  --accent-contrast-300: #000000;
  --accent-contrast-400: #000000;
  --accent-contrast-500: #000000;
  --accent-contrast-600: #000000;
  --accent-contrast-700: #000000;
  --accent-contrast-800: #ffffff;
  --accent-contrast-900: #ffffff;
  --accent-contrast-a100: #000000;
  --accent-contrast-a200: #000000;
  --accent-contrast-a400: #000000;
  --accent-contrast-a700: #000000;

  --warn-50: #fce7eb;
  --warn-100: #f8c3cd;
  --warn-200: #f39bab;
  --warn-300: #ee7389;
  --warn-400: #ea5570;
  --warn-500: #e63757;
  --warn-600: #e3314f;
  --warn-700: #df2a46;
  --warn-800: #db233c;
  --warn-900: #d5162c;
  --warn-a100: #ffffff;
  --warn-a200: #ffd3d7;
  --warn-a400: #ffa0a8;
  --warn-a700: #ff8691;
  --warn-contrast-50: #000000;
  --warn-contrast-100: #000000;
  --warn-contrast-200: #000000;
  --warn-contrast-300: #000000;
  --warn-contrast-400: #000000;
  --warn-contrast-500: #ffffff;
  --warn-contrast-600: #ffffff;
  --warn-contrast-700: #ffffff;
  --warn-contrast-800: #ffffff;
  --warn-contrast-900: #ffffff;
  --warn-contrast-a100: #000000;
  --warn-contrast-a200: #000000;
  --warn-contrast-a400: #000000;
  --warn-contrast-a700: #000000;
  --alert-background: #ff96964d;
  --alert-foreground: #910000;
}

$front-end-primary: (
  50 : #F3E5E4,
  100 : #E0BEBC,
  200 : #cb9390,
  300 : #b66763,
  400 : #a74741,
  500 : #972620,
  600 : #8f221c,
  700 : #841c18,
  800 : #7a1713,
  900 : #690d0b,
  a100 : #ff9d9b,
  a200 : #ff6a68,
  a400 : #ff3835,
  a700 : #ff1f1c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
);

$front-end-accent: (
  50 : #f9e4e5,
  100 : #f1bcbe,
  200 : #e79093,
  300 : #dd6367,
  400 : #d64147,
  500 : #cf2026,
  600 : #ca1c22,
  700 : #c3181c,
  800 : #bd1317,
  900 : #b20b0d,
  A100 : #ffdfdf,
  A200 : #ffacac,
  A400 : #ff797a,
  A700 : #ff5f60,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
);

$front-end-warn: (
  50: var(--warn-50),
  100: var(--warn-100),
  200: var(--warn-200),
  300: var(--warn-300),
  400: var(--warn-400),
  500: var(--warn-500),
  600: var(--warn-600),
  700: var(--warn-700),
  800: var(--warn-800),
  900: var(--warn-900),
  A100: var(--warn-a100),
  A200: var(--warn-a200),
  A400: var(--warn-a400),
  A700: var(--warn-a700),
  contrast: (
    50: var(--warn-contrast-50),
    100: var(--warn-contrast-100),
    200: var(--warn-contrast-200),
    300: var(--warn-contrast-300),
    400: var(--warn-contrast-400),
    500: var(--warn-contrast-500),
    600: var(--warn-contrast-600),
    700: var(--warn-contrast-700),
    800: var(--warn-contrast-800),
    900: var(--warn-contrast-900),
    A100: var(--warn-contrast-a100),
    A200: var(--warn-contrast-a200),
    A400: var(--warn-contrast-a400),
    A700: var(--warn-contrast-a700),
  ),
);


$header-height: 64px;
$footer-height: 50px;
